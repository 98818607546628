import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

const CardStyles = styled.div`
  background: white;
  box-shadow: ${Theme.boxShadow};
  border-radius: ${Theme.borderRadius};
  padding: ${props => props.padding || '30px'};
  
  @media ${deviceMediaQ.tablet} {
    padding: 20px;
  }
`;

export default CardStyles;
