import { Link } from 'gatsby';
import styled from 'styled-components';

const HeaderAnchorStyles = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: #384C72;
  letter-spacing: 0.14em;
  font-size: 13px;
  font-weight: 500;
  margin: auto 15px auto 15px;
  padding: 8px 0 8px 0;
  position: relative;

  &.active {
    font-weight: 800;
  }
`;

export default HeaderAnchorStyles;
