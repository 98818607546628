import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

const PageContainerStyles = styled.div`
  padding: 0 ${Theme.pageContainerPaddingSz};
  max-width: ${Theme.pageMaxSz};
  margin-left: auto;
  margin-right: auto;
  
  @media ${deviceMediaQ.tablet} {  
    margin: 0;
    padding: 0 ${Theme.pageContainerPaddingMobileSz}; 
  }
  
  &.add-margin-to-children {
    & > * {
      margin-bottom: 50px;
    }
  }
`;

export default PageContainerStyles;
