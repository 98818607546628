import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';

import Header from '@components/header/header.component';
import Footer from '@components/footer/footer.component';
import Seo from '@components/seo';

import Theme from '@src/theme';
import ModalProvider from '@src/store/modal/modal-context';

import Main from './main.styles';

import GlobalStyles from '../../app.styles';

const LayoutComponent = ({ children, title, location, seoOptions = {}, headerOptions = {} }) => {
  return (
    <ModalProvider>
      <GlobalStyles />
      <Seo title={title} {...seoOptions} />
      <Header location={location} {...headerOptions} />
      <Main>{children}</Main>
      <Footer location={location} />
      <CookieConsent
        enableDeclineButton
        buttonText='Permitir cookies necessários'
        declineButtonText='Recusar'
        buttonStyle={{
          backgroundColor: Theme.secondaryColor,
          color: 'white',
        }}
        declineButtonStyle={{
          backgroundColor: '#f8f9fa',
          color: Theme.darkColor,
        }}
      >
        Utilizamos cookies principalmente para analisar nosso tráfego, personalizar conteúdo e fornecer funcionalidades de redes sociais
      </CookieConsent>
    </ModalProvider>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default LayoutComponent;
