import styled from 'styled-components';
import Theme, { deviceMediaQ } from '@src/theme';

export default styled.footer`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${Theme.primaryColor};
  border-top-left-radius: ${Theme.borderRadius};
  border-top-right-radius: ${Theme.borderRadius};
  height: ${Theme.headerSize};
  box-sizing: border-box;

  @media ${deviceMediaQ.tablet} {
    margin-top: 20px;
  }

  .footer-container {
    max-width: ${Theme.headerMaxWidth};
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;

    @media ${deviceMediaQ.mobile} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .icons {
    justify-self: end;
    margin-left: auto;
    display: flex;
    align-items: center;

    > :not(:first-child) {
      margin-left: 20px;
    }
  }
`;
