import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

import HeaderAnchor from './header-anchor/header-anchor.styles';

const menuMediaBreak = deviceMediaQ.tablet;

export const HeaderStyles = styled.header`
  height: ${Theme.headerSize};
  border-bottom-left-radius: ${Theme.borderRadius};
  border-bottom-right-radius: ${Theme.borderRadius};
  background-color: white;

  &.shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  }

  z-index: 100;
  
  .nav-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
    max-width: ${Theme.headerMaxWidth};
    margin: 0 auto;
    
    @media ${menuMediaBreak} {
      padding-left: 23px;
      padding-right: 23px;
    }
  }

  .menu-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;

    @media ${menuMediaBreak} {
      display: flex;
    }
  }
  
  .cdf-logo {
    display: flex;
    justify-content: center;
    
    svg {
      height: 60px;
      width: auto;
    }
    
    @media ${menuMediaBreak} {
      align-items: center;
      width: 100%;
    }
  }
`;

export const NavStyles = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${menuMediaBreak} {
    display: none;
  }

  .nav-links {
    .nav-actions {
      display: none;
    }

    @media ${menuMediaBreak} {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  &.open {
    z-index: 101;
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh; // fallback
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(21, 21, 21, 0.75);
    flex-direction: column;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);

    ${HeaderAnchor} {
      color: white !important;
      margin: 15px auto;
      font-size: 18px;
      font-weight: 500;
    }

    .nav-actions {
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: white;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translate(-25px, 0);
      cursor: pointer;

      .close-nav-links {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
