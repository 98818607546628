import React from 'react';
import { CLOSE_MODAL, OPEN_MODAL, SET_MODAL_DATA, TOGGLE_MODAL } from '@src/store/modal/modal.consts';

const initialState = {
  open: false,
  data: null,
};

const ModalContext = React.createContext(initialState);

const modalReducer = (state = initialState, { action, payload }) => {
  switch (action) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        open: false,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        open: !state.open,
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

const ModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  const value = { state, dispatch };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  return React.useContext(ModalContext);
};

export default ModalProvider;
