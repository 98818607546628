import React from 'react';

import Footer from './footer.styles';

import CDFWhiteLogo from '@images/cdf-white-logo.svg';
import FacebookIcon from '@icons/facebook.svg';
import InstagramIcon from '@icons/instagram.svg';

const FooterComponent = ({ location }) => {
  const isIndexPage = location.pathname === '/';

  return (
    <Footer style={{ marginTop: isIndexPage ? '0' : '75px' }}>
      <div className='footer-container'>
        <CDFWhiteLogo />
        <div className='icons'>
          <a href='https://www.facebook.com/equipamentoshospitalar' rel='noreferrer' target='_blank'><FacebookIcon /></a>
          <a href='https://www.instagram.com/cdf_equipamentos' rel='noreferrer' target='_blank'><InstagramIcon /></a>
        </div>
      </div>
    </Footer>
  );
};

export default FooterComponent;
