export const mediaSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const mkDeviceMedia = () => Object.entries(mediaSize).reduce((acc, [name, size]) => ({
  ...acc,
  [name]: `only screen and (max-width: ${size})`,
  [`not${name[0].toUpperCase()}${name.slice(1)}`]: `not only screen and (max-width: ${size})`
}), {});

export const deviceMediaQ = mkDeviceMedia();

const THEME_CONSTANTS = {
  // COLORS
  primaryColor: '#52668C',
  secondaryColor: '#587EEC',
  darkColor: '#23242A',
  pastelColor: '#FAFBFD',
  lightColor: '#FFFFFF',

// BORDERS
  borderRadius: '8px',
  labelBorderRadius: '10px',
  buttonBorderRadius: '5px',

// SHADOWS
  boxShadow: '0px 4px 18px rgba(88, 126, 236, 0.18)',
  buttonBoxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',

// SIZES
  headerSize: '109px',
  pageContainerPaddingSz: '98px',
  pageContainerPaddingMobileSz: '20px',
  pageMaxSz: '1200px',
  headerMaxWidth: '1500px',
};

export default THEME_CONSTANTS;
