/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Theme from '@src/theme';

const structuredDataOrganization = (logo, siteUrl) => `
[
  {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "@id": "${siteUrl}",
    "name": "CDF Equipamentos",
    "legalName": "CDF Equipamentos LTDA-ME",
    "url": "${siteUrl}",
    "image": ["${siteUrl}/cdf_facade.jpeg"],
    "logo": "${logo}",
    "foundingDate": "2014-10-24",
    "telephone": "+55(54)32924473",
    "founders": [
      {
        "@type": "Person",
        "name": "Cristiano Zim"
      },
      {
        "@type": "Person",
        "name": "Diego Heitor Becker"
      },
      {
        "@type": "Person",
        "name": "Flávio Wosniak"
      }
    ],
    "contactPoints": [
      {
        "@type": "ContactPoint",
        "email": "cdf@cdfequipamentos.com.br",
        "telephone": "+55(54)32924473",
        "contactType": "sales"
      }
    ],
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Flores da Cunha",
      "streetAddress": "Rua Leandro João Marcon, 507, Bairro Világio (AABB)",
      "addressRegion": "RS",
      "addressCountry": "BR",
      "postalCode": "95270-000"
    },
    "currenciesAccepted": "BRL",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "07:15",
        "closes": "12:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "13:00",
        "closes": "17:00"
      }
    ],
    "paymentAccepted": "Cash, Credit Card",
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-29.06000062346651",
      "longitude": "-51.19233472328722"
    },
    "sameAs": [
      "https://www.instagram.com/cdf_equipamentos",
      "https://www.facebook.com/equipamentoshospitalar"
    ]
  }
]
`;

function Seo({ description, meta, title, ogImage }) {
  const { site, allImageSharp: { nodes: images } } = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  title
                  description
                  author
                  siteUrl
              }
          }
          allImageSharp {
              nodes {
                  gatsbyImageData
              }
          }
      }
  `);

  if (!ogImage) {
    ogImage = 'logo.png';
  }

  const findImg = (imgName) => images.find(({ gatsbyImageData: { images: { fallback: { src } } } }) => {
    const splitSrc = src.split('/');
    const fileName = splitSrc[splitSrc.length - 1];
    if (fileName === imgName)
    return fileName === imgName;
  });

  const { gatsbyImageData: { images: { fallback: { src: ogImageSrc } } } } = React.useMemo(() => findImg(ogImage),
    [images, ogImage],
  );

  const { gatsbyImageData: { images: { fallback: { src: cdfBlueLogoSrc } } } } = React.useMemo(() => findImg('cdf-blue-logo-g.png'),
    [images],
  );

  const siteUrl = site.siteMetadata.siteUrl;
  const metaDescription = description || site.siteMetadata.description;
  const ogImageSecureUrl = siteUrl + ogImageSrc;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'pt-BR',
        dir: 'ltr',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: title,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: ogImageSecureUrl,
        },
        {
          name: 'og:image:secure_url',
          content: ogImageSecureUrl,
          itemprop: 'image',
        },
        {
          name: 'og:image',
          content: ogImageSecureUrl,
          itemprop: 'image',
        },
        {
          name: 'og:image:type',
          content: 'image/png',
        },
        {
          name: 'og:image:width',
          content: '256',
        },
        {
          name: 'og:image:height',
          content: '256',
        },
        {
          name: 'og:image:alt',
          content: 'Logo da CDF Equipamentos',
        },
        {
          name: 'og:locale',
          content: 'pt_BR',
        },
        {
          name: 'image',
          content: ogImageSecureUrl,
        },
        {
          name: 'theme-color',
          content: Theme.primaryColor,
        },
        {
          name: 'robots',
          content: 'index,follow',
        },
      ].concat(meta)}
    >
      <script type='text/javascript'>
        {`
          function setDocHeight() {
            var vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', vh + 'px');
          }
          setDocHeight();
          window.addEventListener('resize', setDocHeight);
        `}
      </script>
      <script type='application/ld+json'>
        {structuredDataOrganization(cdfBlueLogoSrc, siteUrl)}
      </script>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'pt-BR',
  meta: [],
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
