import React from 'react'
import { Link } from 'gatsby';

import HeaderAnchor from '@components/header/header-anchor/header-anchor.component';

import { HeaderStyles as Header, NavStyles as Nav } from './header.styles';

import MenuIcon from '@icons/burger.svg';
import CDFLogo from '@images/cdf-blue-logo.svg';
import CloseIcon from '@icons/close.svg';

const HeaderComponent = ({ location, disableShadow }) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
    }
  }, [menuIsOpen]);

  return (
    <Header className={`${!disableShadow ? 'shadow' : ''}`}>
      <div className='nav-container'>
        <button className='align-center-center menu-button' onClick={() => setMenuIsOpen(true)}>
          <MenuIcon />
        </button>
        <div className='cdf-logo'>
          <Link to='/'><CDFLogo /></Link>
        </div>
        <Nav className={`${menuIsOpen ? 'open' : ''}`}>
          <div className='nav-links'>
            <HeaderAnchor to="/" location={location}>Início</HeaderAnchor>
            <HeaderAnchor to="/sobre-nos" location={location}>Sobre nós</HeaderAnchor>
            <HeaderAnchor to="/produtos" location={location}>Produtos</HeaderAnchor>
            <div className='nav-actions' onClick={() => setMenuIsOpen(false)}>
              <CloseIcon className='close-nav-links'/>
            </div>
          </div>
        </Nav>

      </div>
    </Header>
  );
};

export default HeaderComponent;
