import Theme, { deviceMediaQ } from './theme';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  // GLOBAL OVERWRITES
  body {
    font-family: 'Rubik', sans-serif;
    color: ${Theme.primaryColor};
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  h1 {
    font-size: 84px;
  }

  h2 {
    font-size: 64px;
  }

  h3 {
    font-size: 52px;
  }

  h4 {
    font-size: 44px;
    font-weight: 500;

    .callout {
      font-weight: 700;
      color: ${Theme.secondaryColor};
    }
  }

  p {
    color: ${Theme.darkColor};
    font-size: 1rem;
    line-height: 1.4rem;

    &.scalable {
      @media ${deviceMediaQ.mobile} {
        font-size: 3.5vw !important;
      }
    }
  }

  .subtitle {
    font-weight: bold;
    font-size: 30px;
  }

  .body-text {
    font-style: normal;
    font-weight: normal;

    &.l {
      font-size: 28px;
    }

    &.m {
      font-size: 24px;
    }

    &.s {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .caption {
    font-weight: 500;
    font-size: 23px;
  }

  .align-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .full-height {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .hide-mobile {
    @media ${deviceMediaQ.tablet} {
      display: none;
    }
  }

  .hide-laptop {
    @media ${deviceMediaQ.laptop} {
      display: none;
    }
  }

  .show-mobile {
    display: none;

    @media ${deviceMediaQ.mobile} {
      display: block;
    }
  }

  .show-tablet {
    display: none;

    @media ${deviceMediaQ.tablet} {
      display: block;
    }
  }

  .show-laptop {
    display: none;

    @media ${deviceMediaQ.laptop} {
      display: block;
    }
  }

  .rst-margin-mobile {
    @media ${deviceMediaQ.tablet} {
      margin: 0 !important;
    }
  }

  .header-media {
    height: 220px;
    max-width: ${Theme.pageMaxSz};
    margin: 0 auto;

    @media ${deviceMediaQ.tablet} {
      padding: 0;
      width: 100%;
      height: 150px;
      transform: translateY(-8px);
    }

    img {
      border-radius: 0 0 24px 24px;
      z-index: -1;
    }
  }

  .primary {
    color: ${Theme.primaryColor};
  }

  button {
    outline: none;
    background: inherit;
    cursor: pointer;
  }

  .hoverable {
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 35%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #384C72;
      transform: scaleX(0.0001);
      transition: transform 100ms ease-out;
    }

    &.bluish:after {
      background: ${Theme.secondaryColor};
      height: 2px;
      width: 100%;
      top: 150%;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }

  a {
    text-decoration: none;

    &.button {
      color: white;
    }
  }

  .v-align {
    display: flex;
    align-items: center;
  }

  .relative {
    position: relative;
  }

  .wavy-background {
    position: absolute;
    height: 105%;
    width: 100%;
    color: #f8f9fa;
    z-index: -1;

    &.light {
      background: #FFF;
    }

    &.flip-v {
      transform: rotate(180deg);
    }
  }
  
  .loading {
    display: none;
  }
`;
