import React from 'react';

import HeaderAnchor from './header-anchor.styles';

const HeaderAnchorComponent = ({ children, location, ...props }) => (
  <HeaderAnchor
    className={`hoverable ${location.pathname === props.to ? 'active' : ''}`}
    {...props}
  >
    {children}
  </HeaderAnchor>
);

export default HeaderAnchorComponent;
